import { graphql, Link } from 'gatsby';
import React, { Component } from 'react';
import HeadTags from '../components/global/headTags';
import DefaultLayout from '../layouts/default-layout';
import RoundedButton from '../components/buttons/roundedButton';
import Button from '../components/ui/button';
import SectionNavigation from '../components/corecurriculum/sectionNavigation';
import { makeAnchor } from '../components/article/faqList';
import { navigate } from 'gatsby-link';
import * as style from './ourTeam.module.scss';
import LogoSvg from '../components/global/logoSvg';

class OurTeam extends Component {
	constructor() {
		super();
		this.state = {
			startPage: false,
			isExiting: false,
			width: 0,
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.transition.status === 'exiting') {
			this.setState({ isExiting: true });
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('scroll', this.handleScrollListener);
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			isExiting: false,
		});
		setTimeout(this.startPage, 1000);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScrollListener);
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	handleScrollListener(event) {
		let advisorEl = document.querySelector('#AdvisoryBoard');
		let elDistanceToTop = window.pageYOffset + advisorEl.getBoundingClientRect().top;
		let toTopWrap = document.getElementById("to-top-wrap");
		if (document.scrollingElement.scrollTop > elDistanceToTop) {
			toTopWrap.classList.add('opacity-1');
		} else {
			toTopWrap.classList.remove('opacity-1');
		}
	}

	handleScrollToTop() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		setTimeout(() => {
			window.location.hash = "";
		}, "1000");
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}

	startPage = () => {
		this.setState({ startPage: true });
	};

	render() {
		const {
			title,
			our_team_subhead,
			projects_callout_heading,
			projects_callout_image,
			projects_button_text,
			projects_page_link,
			meta_image,
			meta_description
		} = this.props.data.allItemsJson.edges[0].node;

		const authors = this.props.data.authors.edges;
		const primary = [];
		const advisors = [];
		// const fellows = [];
		const directors = [];

		authors.forEach(function(element) {
			if (element.node.staff_type == "Primary") {
				primary.push(element.node);
			} else if (element.node.staff_type == "Board of Advisors") {
				advisors.push(element.node);
			} else if (element.node.staff_type == "Fellows") {
				// fellows.push(element.node);
			} else if (element.node.staff_type == "Board of Directors") {
				directors.push(element.node);
			}
		});

		const sections = [
			{ section_heading: 'Staff' },
			{ section_heading: 'Advisory Board' },
			// { section_heading: 'Fellows' },
			{ section_heading: 'Board of Directors' },
		];

		return (
			<DefaultLayout {...this.props} excludeParentFunctions>
				<div>
					<HeadTags
						title={title}
						location={this.props.location}
						image={
							meta_image ? meta_image.sizes.hero_md : null
						}
						imageTwitter={
							meta_image ? meta_image.sizes.hero_sm : null
						}
						description={meta_description}
					/>
					{/*
					<div className={style.logo}>
						<Link to="/">
							<LogoSvg />
						</Link>
					</div>
					*/}
					<div id="spacer" className={style.spacer}>&nbsp;</div>

					<div
						id="to-top-wrap"
						className={style.toTopWrap}
						onClick={(e) => this.handleScrollToTop()}
					>
						<a
							className={`icon-circle-orange fa-arrow-up ${style.toTopButton}`}
							rel="noopener"
						>
							<span>TOP</span>
						</a>
						<span className={style.toTopText}>TOP</span>
					</div>

					<div id="our-team-header" className={style.ourTeamHeader}>
						<div className={style.sectionNavigationWrap}>
							<SectionNavigation
								sections={sections}
								currentHash={this.props.location.hash}
							/>
						</div>

						<h1 className={style.ourTeamTitle}>
							{title}
						</h1>
						<p className={style.ourTeamSubhead}>
							{our_team_subhead}
						</p>
					</div>

					<div className={style.container}>
						<div id={makeAnchor('Staff')} className={style.primaryStaff}>
							{primary.length > 0 && primary.map(function(item, i) {
								return (
									<div className={style.primaryItem} data-sort={item.author_sort}>
										<div
											onClick={() => {
												navigate('/our-team/'+item.slug)
											}}
											className={style.bioImage} style={{ backgroundImage: 'url('+item.bio_image+')'}}
										>
											<br />
										</div>
										<h3
											className={style.staffName}
											onClick={() => {
												navigate('/our-team/'+item.slug)
											}}
										>
											{item.title}
										</h3>
										<p 
											onClick={() => {
												navigate('/our-team/'+item.slug)
											}}
											className={style.staffPosition}
										>
											{item.position}
										</p>
										
										<div className={style.socialWrap}>
											{item.email &&
												<a
													href={`mailto:${item.email}`}
													className={`icon-fa-all white-orange-hover fas fa-envelope`}
													target="_blank"
													rel="noopener"
												>
													<span>Email</span>
												</a>
											}
											{item.twitter_link &&
												<a
													href={item.twitter_link}
													className={`icon-social white-orange-hover`}
													target="_blank"
													rel="noopener"
												>
													<div className={`icon-twitter x-white`}></div>
												</a>
											}
											{item.linkedin_link && item.linkedin_link.uri &&
												<a
													href={item.linkedin_link.uri}
													className={`icon-social white-orange-hover fas fa-linkedin-in`}
													target="_blank"
													rel="noopener"
												>
													<span>LinkedIn</span>
												</a>
											}
											{item.bluesky_link && item.bluesky_link.uri &&
												<a
													href={item.bluesky_link.uri}
													className={`icon-social white-orange-hover fas fa-bluesky`}
													target="_blank"
													rel="noopener"
												>
													<span>Bluesky</span>
												</a>
											}
										</div>
									</div>
								);
							})}
						</div>
					</div>
					
					<div className={style.bgGrey}>
						<div className={style.container}>
							<div id={makeAnchor('Advisory Board')} className={style.advisorStaff}>
								<h2 className={`color-black ${style.sectionHeading}`}>
									Advisory Board
								</h2>
								<div className={style.advisorStaffInset}>
									{advisors.length > 0 && advisors.map(function(item, i) {
										return (
											<div className={style.advisorItem} data-sort={item.author_sort}>
												<div
													onClick={() => {
														navigate('/our-team/'+item.slug)
													}}
													className={style.bioImage} style={{ backgroundImage: 'url('+item.bio_image+')'}}
												>
													<br />
												</div>
												<h3
													onClick={() => {
														navigate('/our-team/'+item.slug)
													}}
													className={style.staffName}
												>
													{item.title}
												</h3>
												<p
													onClick={() => {
														navigate('/our-team/'+item.slug)
													}}
													className={style.staffPosition}
												>
													{item.position}
												</p>
												
												<div className={style.socialWrap}>
													{item.email &&
														<a
															href={`mailto:${item.email}`}
															className={`icon-fa-all white-orange-hover fas fa-envelope`}
															target="_blank"
															rel="noopener"
														>
															<span>Email</span>
														</a>
													}
													{item.twitter_link &&
														<a
															href={item.twitter_link}
															className={`icon-social black-orange-hover`}
															target="_blank"
															rel="noopener"
														>
															<div className={`icon-twitter x-black`}></div>
														</a>
													}
													{item.linkedin_link && item.linkedin_link.uri &&
														<a
															href={item.linkedin_link.uri}
															className={`icon-social black-orange-hover fas fa-linkedin-in`}
															target="_blank"
															rel="noopener"
														>
															<span>LinkedIn</span>
														</a>
													}
													{item.bluesky_link && item.bluesky_link.uri &&
														<a
															href={item.bluesky_link.uri}
															className={`icon-social white-orange-hover fas fa-bluesky`}
															target="_blank"
															rel="noopener"
														>
															<span>Bluesky</span>
														</a>
													}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					
					{/*
					<div className={style.bgBlack}>
						<div className={style.container}>
							<div id={makeAnchor('Fellows')} className={style.fellowsStaff}>
								<h2 className={`color-white ${style.sectionHeading}`}>
									Fellows
								</h2>
								<div className={style.fellowsStaffInset}>
									{fellows.length > 0 && fellows.map(function(item, i) {
										return (
											<div className={style.fellowItem} data-sort={item.author_sort}>
												<div
													onClick={() => {
														navigate('/fellows/'+item.slug)
													}}
													className={style.bioImage} style={{ backgroundImage: 'url('+item.bio_image+')'}}
												>
													<br />
												</div>
												<h3
													onClick={() => {
														navigate('/fellows/'+item.slug)
													}}
													className={style.staffName}
												>
													{item.title}
												</h3>
												<p
													onClick={() => {
														navigate('/fellows/'+item.slug)
													}}
													className={style.staffPosition}
												>
													{item.position}
												</p>
												
												<div className={style.socialWrap}>
													{item.twitter_link &&
														<a
															href={item.twitter_link}
															className={`icon-social white-orange-hover icon-twitter`}
															target="_blank"
															rel="noopener"
														>
															<span>Twitter</span>
														</a>
													}
													{item.linkedin_link && item.linkedin_link.uri &&
														<a
															href={item.linkedin_link.uri}
															className={`icon-social white-orange-hover fas fa-linkedin-in`}
															target="_blank"
															rel="noopener"
														>
															<span>LinkedIn</span>
														</a>
													}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					*/}

					<div className={style.bgBlack}>
						<div className={style.container}>
							<div id={makeAnchor('Board of Directors')} className={style.directorsStaff}>
								{/* color-black */}
								<h2 className={`color-white ${style.sectionHeading}`}>
									Board of Directors
								</h2>
								<div className={style.directorsStaffInset}>
									{directors.length > 0 && directors.map(function(item, i) {
										return (
											<div className={style.directorItem} data-sort={item.author_sort}>
												<div
													onClick={() => {
														navigate('/our-team/'+item.slug)
													}}
													className={style.bioImage} style={{ backgroundImage: 'url('+item.bio_image+')'}}
												>
													<br />
												</div>
												<h3
													onClick={() => {
														navigate('/our-team/'+item.slug)
													}}
													className={style.staffName}
												>
													{item.title}
												</h3>
												<p
													onClick={() => {
														navigate('/our-team/'+item.slug)
													}}
													className={style.staffPosition}
												>
													{item.position}
												</p>
												
												{/* white-orange-hover */}
												{/* black-orange-hover */}
												<div className={style.socialWrap}>
													{item.email &&
														<a
															href={`mailto:${item.email}`}
															className={`icon-fa-all white-orange-hover fas fa-envelope`}
															target="_blank"
															rel="noopener"
														>
															<span>Email</span>
														</a>
													}
													{item.twitter_link &&
														<a
															href={item.twitter_link}
															className={`icon-social white-orange-hover ${style.socialItem}`}
															target="_blank"
															rel="noopener"
														>
															<span>Twitter</span>
															<div className={`icon-twitter x-white`}></div>
														</a>
													}
													{item.linkedin_link && item.linkedin_link.uri &&
														<a
															href={item.linkedin_link.uri}
															className={`icon-social white-orange-hover fas fa-linkedin-in`}
															target="_blank"
															rel="noopener"
														>
															<span>LinkedIn</span>
														</a>
													}
													{item.bluesky_link && item.bluesky_link.uri &&
														<a
															href={item.bluesky_link.uri}
															className={`icon-social white-orange-hover fas fa-bluesky`}
															target="_blank"
															rel="noopener"
														>
															<span>Bluesky</span>
														</a>
													}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>

					{/* style={{ backgroundImage: 'url('+projects_callout_image+')'}} */}
					<div
						className={style.projectsCallout}
					>

						<div
							className={style.projectsCalloutImage}
							style={{ backgroundImage: 'url('+projects_callout_image+')'}}
						></div>

						<p className={style.projectsCalloutHeading}>
							{projects_callout_heading}
						</p>

						<span className={style.textLine} />

						<div
							className={style.projectsButtonWrap}
						>
							<RoundedButton
								centered={true}
								color={'white'}
								text={projects_button_text}
								onClick={() => {
										navigate(projects_page_link)
								}} type="button"
								url={projects_page_link}
							/>
						</div>

					</div>
					{/* */}

				</div>
			</DefaultLayout>
		);
	}
}

export default OurTeam;

// eslint-disable-next-line
export const corecurriculumPageQuery = graphql`
query OurTeamPage(
	$path: String!
	$menuId: String!
	$isEs: Boolean!
	$idRegex: String!
	$blog: String!
	$translations: String!) {
	allItemsJson(filter: { path: { eq: $path } }) {
	  edges {
		node {
			meta_image {
				sizes {
					hero_md
					hero_sm
				}
			}
			meta_description
		  path
		  meta_description
		  short_description
		  title
		  our_team_subhead
			projects_callout_heading
			projects_callout_image
			projects_button_text
			projects_page_link
		}
	  }
	}
	authors: allItemsJson(
		filter: {content_type: {eq: "author"}, staff_type: {ne: ""}, testing: {eq: false}},
		sort: {fields: author_sort, order: ASC}
	) {
		edges {
			node {
				jsonId
				title
				email
				position
				staff_type
				bio_image
				twitter_link
				linkedin_link {
					uri
					title
				}
				bluesky_link {
					uri
					title
				}
				slug
				author_sort
				testing
			}
		}
	}
	social: allItemsJson(filter: { jsonId: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
	...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
	...headerFieldsEs @include(if:$isEs)
	...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }  
`;